import axios from 'axios';
import { toast } from 'react-toastify';

export const getMessages = async (params) => {
  try {
    const { data } = await axios.get('/api/messages', {
      params,
    });
    return data;
  } catch (error) {
    toast.error(error.message);
    throw error;
  }
};
