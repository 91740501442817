import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import { makeStyles, Box } from '@material-ui/core';
import Page from 'src/components/common/Page';
import Messages from 'src/components/Chat/Messages';
import Search from 'src/components/Chat/Search';
import ThreadList from 'src/components/Chat/ThreadList';
import { handleJoinConversation, getRecipient } from 'src/pages/Chat/api';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
  },
  box: {
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
  },
  sidebar: {
    backgroundColor: theme.palette.background.default,
    borderRight: `1px solid ${theme.palette.divider}`,
    width: 320,
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  hideSidebar: {
    display: 'none',
  },
}));

const Chat = () => {
  const [recipient, setRecipient] = useState(null);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const { patientId } = queryString.parse(window.location.search);
  const classes = useStyles();

  const handleGetRecipient = async (patientId) => {
    try {
      handleJoinConversation(patientId);
      const recipient = await getRecipient(patientId);
      setRecipient(recipient);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSetIsSearchOpen = (value) => {
    setIsSearchOpen(value);
  };

  useEffect(() => {
    (async () => {
      if (patientId) {
        handleGetRecipient(patientId);
      } else {
        setRecipient(null);
      }
    })();
  }, [patientId]);

  return (
    <Page className={classes.root} title="Chat">
      <Box className={classes.box}>
        <Box className={classes.sidebar}>
          <Search
            handleSetIsSearchOpen={handleSetIsSearchOpen}
            isSearchOpen={isSearchOpen}
          />

          {!isSearchOpen && (
            <ThreadList
              handleGetRecipient={handleGetRecipient}
              patientId={patientId}
              recipient={recipient}
            />
          )}
        </Box>

        <Messages recipient={recipient} />
      </Box>
    </Page>
  );
};

export default Chat;
