import axios from 'axios';
import { socket } from 'src/socket';

export const getRecipient = async (patientId, phoneNumber) => {
  try {
    const { data: recipient } = await axios.get(
      `/api/patients/${patientId}/recipient`
    );
    return recipient;
  } catch (error) {
    throw error;
  }
};

export const handleJoinConversation = (patientId) => {
  // Leave any previous conversations
  socket.emit('leave_conversation');
  // Join the new conversation
  socket.emit('join_conversation', patientId);
};
