import { TextField, InputAdornment, makeStyles } from '@material-ui/core';
import { HighlightOff as CloseIcon, Search as SearchIcon } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  closeIconClass: {
    cursor: 'pointer',
  },
}));

const DoulaSearch = ({ keyword, handleSetKeyword }) => {
  const { closeIconClass } = useStyles();

  return (
    <TextField
      fullWidth
      value={keyword}
      variant="outlined"
      type="text"
      placeholder="Search Doulas"
      onChange={(e) => handleSetKeyword(e.target.value)}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            {keyword.length > 0 && (
              <CloseIcon
                className={closeIconClass}
                onClick={() => handleSetKeyword('')}
                hidden={keyword.length <= 0}
              />
            )}
          </InputAdornment>
        ),
      }}
    />
  );
};

export default DoulaSearch;
