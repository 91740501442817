import React, { useEffect, useReducer } from 'react';
import BidirectionalInfinitScroll from './BidirectionalInfinitScroll';
import PropTypes from 'prop-types';
import { makeStyles, Box, Button, SvgIcon } from '@material-ui/core';
import MessageItem from './MessageItem';
import { socket } from 'src/socket';
import { ChevronLeft } from 'react-feather';
import queryString from 'query-string';
import { getMessages, getMessagePage } from 'src/components/Chat/api';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
  },

  loading: {
    opacity: 0.5,
    pointerEvents: 'none',
  },

  navigationWrapper: {
    minHeight: '3rem',
    position: 'fixed',
    top: 64,
    backgroundColor: 'white',
    width: '100%',
    zIndex: 2,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));

const initialState = {
  messages: [],
  messagesCurrentPage: 1,
  messagesTotal: null,
  messagesTotalPages: null,
  currentPages: [],
};

const messagesState = (state, action) => {
  switch (action.type) {
    case 'SET_MESSAGES_DATA':
      return {
        ...state,
        messages: action.payload.results,
        messagesCurrentPage: action.payload.currentPage,
        messagesTotalPages: action.payload.totalPages,
        // Add new pages to current pages
        currentPages: [...state.currentPages, action.payload.currentPage],
      };
    case 'SET_RESET_MESSAGES_DATA':
      return initialState;

    case 'SET_MESSAGES':
      return {
        ...state,
        messages: action.payload,
      };

    default:
      return state;
  }
};

const MessageList = () => {
  const history = useHistory();
  const [state, dispatch] = useReducer(messagesState, initialState);
  const { messages, messagesCurrentPage, messagesTotalPages, currentPages } =
    state;
  const { messageId, patientId } = queryString.parse(window.location.search);
  const classes = useStyles();

  useEffect(() => {
    // Manage incoming messages
    const handleNewMessage = (message) => {
      const newMessages = [...messages, message];

      dispatch({ type: 'SET_MESSAGES', payload: newMessages });
    };
    socket.on('new_message', handleNewMessage);
    return () => {
      socket.off('new_message', handleNewMessage);
    };
  }, [messages]);

  useEffect(() => {
    (async () => {
      try {
        // Having to reset the state to reset scroll position
        dispatch({ type: 'SET_RESET_MESSAGES_DATA' });

        let page = initialState.messagesCurrentPage;
        if (messageId) {
          // Find the page the selected message is on
          page = await getMessagePage({ messageId, patientId });
        }

        if (!patientId) return;
        const messagesData = await await getMessages({
          patientId,
          page,
        });

        dispatch({ type: 'SET_MESSAGES_DATA', payload: messagesData });
      } catch (error) {
        console.log(error);
      }
    })();
  }, [patientId, messageId]);

  const handleBackClick = () => {
    history.push(`/chat`);
  };

  if (!patientId) return null;

  return (
    <Box height="100%">
      <Box className={classes.navigationWrapper}>
        <Button
          onClick={handleBackClick}
          startIcon={
            <SvgIcon size="small">
              <ChevronLeft />
            </SvgIcon>
          }
        >
          Back
        </Button>
      </Box>

      <Box height="100%" width="100%" overflow="auto">
        <BidirectionalInfinitScroll
          scrollToId={messageId}
          currentPage={messagesCurrentPage}
          fetchMoreTop={async () => {
            try {
              // Find the heighest page
              const heighestPage = Math.max(...currentPages);

              // Check if there are more pages above
              if (heighestPage < messagesTotalPages) {
                // Add 1 to heighestPage to get the next page
                const newPage = heighestPage + 1;

                const messagesData = await getMessages({
                  patientId,
                  page: newPage,
                });

                messagesData.results = [...messagesData?.results, ...messages];
                dispatch({ type: 'SET_MESSAGES_DATA', payload: messagesData });
              } else {
                console.log('No more pages on top');
                return;
              }
            } catch (error) {
              console.log(error);
            }
          }}
          fetchMoreBottom={async () => {
            try {
              // Find the lowest page
              const lowestPage = Math.min(...currentPages);

              // Check if there are more pages below
              if (lowestPage > 1) {
                // Subtract 1 from lowestPage to get the previous page
                const newPage = lowestPage - 1;

                const messagesData = await getMessages({
                  patientId,
                  page: newPage,
                });

                messagesData.results = [...messages, ...messagesData?.results];
                dispatch({ type: 'SET_MESSAGES_DATA', payload: messagesData });
              } else {
                console.log('No more pages on bottom');
                return;
              }
            } catch (error) {
              console.log(error);
            }
          }}
        >
          {/* Need to reverse to adjust for user expectation to see latest at bottom */}
          {messages?.map((message, index) => (
            <MessageItem
              key={index}
              message={message}
              isSelected={message.id === messageId}
            />
          ))}
        </BidirectionalInfinitScroll>
      </Box>
    </Box>
  );
};

MessageItem.propTypes = {
  className: PropTypes.string,
  Messages: PropTypes.array,
};

export default MessageList;
