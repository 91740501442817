import { useReducer, useEffect } from 'react';
import { Box } from '@material-ui/core';
import SearchList from './SearchList';
import Filter from './Filter';
import { getMessages } from 'src/components/Chat/Search/api';

const initialState = {
  isLoading: false,
  messageSearchResults: [],
  messageSearchCurrentPage: 1,
  messageSearchTotalPages: null,
  currentSearchPage: 1,
  query: {
    fromDate: null,
    toDate: null,
    keyword: '',
    filterByUserType: '',
  },
};

const searchState = (state, action) => {
  switch (action.type) {
    case 'SET_MESSAGE_SEARCH_DATA':
      return {
        ...state,
        messageSearchResults: action.payload.results,
        messageSearchCurrentPage: parseInt(action.payload.currentPage),
        messageSearchTotalPages: action.payload.totalPages,
      };
    case 'SET_QUERY':
      return {
        ...state,
        query: {
          ...state.query,
          ...action.payload,
        },
      };

    case 'RESET':
      return initialState;

    default:
      return state;
  }
};

const highlightKeyword = (content, keyword) =>
  content.replace(new RegExp(`(${keyword})`, 'gi'), '**$1**');

const Search = ({ handleSetIsSearchOpen, isSearchOpen }) => {
  const [state, dispatch] = useReducer(searchState, initialState);
  const { query } = state;

  const handleSetQuery = (value) => {
    const payload = { ...query, ...value };
    dispatch({ type: 'SET_QUERY', payload });
  };

  const handleSearchMessages = async (page) => {
    try {
      dispatch({ type: 'SET_IS_LOADING', payload: true });

      const data = await getMessages({ page, ...query });

      // Check if this is the first page of results
      // If first page, set the results to the data
      // If not first page, append the results to the existing results
      const results =
        page === 1
          ? data.results
          : [...state.messageSearchResults, ...data.results];

      // If a keyword is present in the query, highlight it in the results
      if (query.keyword) {
        results.forEach((message) => {
          message.body = highlightKeyword(message.body, query.keyword);
        });
      }

      dispatch({
        type: 'SET_MESSAGE_SEARCH_DATA',
        payload: {
          ...data,
          // Append new results to existing results
          results,
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleClearSearch = () => {
    dispatch({ type: 'RESET' });
  };

  // Search messages on initial render so the list is not empty
  useEffect(() => {
    handleSearchMessages(1);
  }, []);

  // Adjust height for sarch container height
  const heightOffset = isSearchOpen ? 356.5 + 48 : 48;

  return (
    <>
      <Filter
        query={query}
        handleSetQuery={handleSetQuery}
        handleSearchMessages={handleSearchMessages}
        handleSetIsSearchOpen={handleSetIsSearchOpen}
        handleClearSearch={handleClearSearch}
      />

      {isSearchOpen && (
        <Box height={`calc(100% - ${heightOffset}px)`}>
          <SearchList
            state={state}
            handleSearchMessages={handleSearchMessages}
          />
        </Box>
      )}
    </>
  );
};

export default Search;
