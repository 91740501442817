import axios from 'axios';
import { toast } from 'react-toastify';

export const getMessages = async (params) => {
  try {
    const { data } = await axios.get('/api/messages', {
      params,
    });

    // Reverse to adjust for user expectation to see latest at bottom
    data.results = [...data.results].reverse();

    return data;
  } catch (error) {
    toast.error(error.message);
    throw error;
  }
};

export const getMessagePage = async ({ patientId, messageId }) => {
  try {
    const { data } = await axios.get(`/api/messages/page`, {
      params: { patientId, messageId },
    });
    return data;
  } catch (error) {
    toast.error(error.message);
    throw error;
  }
};
