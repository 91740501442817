import React, { useEffect, useReducer } from 'react';
import ThreadItem from './ThreadItem';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import { socket } from 'src/socket';
import InfiniteScroll from 'react-infinite-scroll-component';
import { getPatientThreads } from './api';
import useDevice from 'src/utils/useDevice';

const initialState = {
  threads: [],
  threadsCurrentPage: 1,
  threadsTotal: null,
  threadsTotalPages: null,
  areThreadsLoading: false,
};

const threadListState = (state, action) => {
  switch (action.type) {
    case 'SET_THREADS_DATA':
      return {
        ...state,
        threads: action.payload.results,
        threadsCurrentPage: action.payload.currentPage,
        threadsTotalPages: action.payload.totalPages,
        areThreadsLoading: false,
      };
    case 'SET_THREADS':
      return {
        ...state,
        threads: action.payload,
      };

    default:
      return state;
  }
};

const ThreadList = ({ recipient, handleGetRecipient, patientId }) => {
  const [state, dispatch] = useReducer(threadListState, initialState);
  const { isDesktop } = useDevice();
  const { threads, threadsCurrentPage, threadsTotalPages } = state;

  const handleGetMoreThreads = async () => {
    try {
      const newPage = threadsCurrentPage + 1;
      const threadsData = await getPatientThreads(newPage);
      threadsData.results = [...threads, ...threadsData.results];
      dispatch({ type: 'SET_THREADS_DATA', payload: threadsData });
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdateThreads = (updatedThread) => {
    // Find the thread in our current list and replace with the updated thread
    const updatedThreads = threads.map((thread) => {
      if (thread.id === updatedThread.id) {
        return updatedThread;
      }
      return thread;
    });

    // Sort threads by createdAt
    updatedThreads.sort((a, b) => {
      return new Date(b.createdAt) - new Date(a.createdAt);
    });

    dispatch({ type: 'SET_THREADS', payload: updatedThreads });
  };

  // Only get threads on first render
  useEffect(() => {
    (async () => {
      try {
        const threadsData = await getPatientThreads(threadsCurrentPage);
        dispatch({ type: 'SET_THREADS_DATA', payload: threadsData });
      } catch (error) {
        console.log(error);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    socket.on('update_threads', (updatedThread) => {
      handleUpdateThreads(updatedThread);
      if (!patientId) return;
      handleGetRecipient(patientId);
    });

    return () => {
      socket.off('update_threads');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [threads, patientId]);

  let filteredThreads = threads;

  if (recipient && threads) {
    filteredThreads = threads?.filter((thread) => thread.id !== recipient.id);
  }

  // Calculate height offset
  const heightOffset = recipient ? 48 + 57 : 48;

  // Hide on mobile when patientId is present
  if (patientId && !isDesktop) return null;

  return (
    // Need to set a max height to support scroll when client browser is zoomed out
    <Box height={`calc(100% - ${heightOffset}px)`}>
      {recipient && <ThreadItem thread={recipient} active />}
      <Box id="thread-list" height="100%" maxHeight="1000px" overflow="auto">
        <InfiniteScroll
          dataLength={threads.length}
          // Need to assume true for endMessage to not show until intial state has been updated with thread data
          hasMore={
            !Boolean(threadsTotalPages)
              ? true
              : threadsCurrentPage < threadsTotalPages
          }
          next={handleGetMoreThreads}
          scrollableTarget="thread-list"
          endMessage={
            <Box py={2}>
              <Typography
                variant="subtitle2"
                color="textSecondary"
                align="center"
              >
                No more threads
              </Typography>
            </Box>
          }
        >
          {filteredThreads?.map((thread) => (
            <ThreadItem key={thread.id} thread={thread} />
          ))}
        </InfiniteScroll>
      </Box>
    </Box>
  );
};

ThreadList.propTypes = {
  recipient: PropTypes.object,
};

export default ThreadList;
