import React, { useRef, useEffect } from 'react';
import { Box } from '@material-ui/core';
import usePrevious from 'src/utils/usePrevious';
import delay from 'delay';

const BidirectionalInfinitScroll = ({
  fetchMoreTop,
  fetchMoreBottom,
  children,
  scrollToId,
  currentPage,
}) => {
  const topRef = useRef(null);
  const bottomRef = useRef(null);
  const scrollRef = useRef(null);
  const selectedItemRef = useRef(null);
  const hasScrolledIntoView = useRef(false);
  const previousScrollToId = usePrevious(scrollToId);

  useEffect(() => {
    if (!Boolean(children.length)) return;

    // Manage the scroll to position
    if (selectedItemRef.current && !hasScrolledIntoView.current) {
      // Scroll to the selected item
      selectedItemRef.current?.scrollIntoView({
        block: 'center',
        inline: 'center',
      });

      hasScrolledIntoView.current = true;
    }

    // Need to reset hasScrolledIntoView if the scrollToId changes
    if (previousScrollToId !== scrollToId) {
      hasScrolledIntoView.current = false;
    }

    // Determine were on the first page and are not scrolling to a specific item
    if (!selectedItemRef.current && currentPage === 1) {
      // Scroll to the bottom
      scrollRef.current.scrollTop = scrollRef.current?.scrollHeight;
    }
  }, [children, previousScrollToId, currentPage, scrollToId]);

  useEffect(() => {
    if (!Boolean(children.length)) return;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(async (entry) => {
          if (entry.isIntersecting) {
            if (entry.target.id === 'top') {
              const oldScrollHeight = scrollRef.current.scrollHeight;

              console.log('Fetching more top ');
              // Add delay to alliviate the double fetch
              await delay(1000);

              await fetchMoreTop();

              const newScrollHeight = scrollRef.current.scrollHeight;
              scrollRef.current.scrollTop += newScrollHeight - oldScrollHeight;
            } else if (entry.target.id === 'bottom') {
              console.log('Fetching more bottom');
              // Add delay to alliviate the double fetch
              await delay(1000);

              fetchMoreBottom();
            }
          }
        });
      },
      { thresholds: 0 }
    );

    if (topRef.current) {
      observer.observe(topRef.current);
    }

    if (bottomRef.current) {
      observer.observe(bottomRef.current);
    }
    const currentTopRef = topRef.current;
    const currentBottomRef = bottomRef.current;
    return () => {
      if (currentTopRef) {
        observer.unobserve(currentTopRef);
      }

      if (currentBottomRef) {
        observer.unobserve(currentBottomRef);
      }
    };
  }, [fetchMoreTop, fetchMoreBottom, children]);

  return (
    <Box ref={scrollRef} height="100%" overflow="auto">
      <Box id="top" position="relative" ref={topRef} width="100%" top={1} />

      {children.map((item, index) => (
        <Box ref={item.props.isSelected ? selectedItemRef : null} key={index}>
          {item}
        </Box>
      ))}

      <Box
        id="bottom"
        position="relative"
        ref={bottomRef}
        width="100%"
        bottom={1}
      />
    </Box>
  );
};

export default BidirectionalInfinitScroll;
