import { useState } from 'react';
import {
  makeStyles,
  TextField,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import {
  HighlightOff as CloseIcon,
  Event as CalendarIcon,
} from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  datePicker: {
    marginTop: 0,
    marginBottom: 0,
    '& input': {
      fontSize: 14,
      padding: 10,
      height: 40,
    },
    '& button': {
      padding: 0,
    },
    '& .MuiOutlinedInput-adornedEnd': {
      paddingRight: 5,
    },
    '& .MuiInputAdornment-root': {
      marginLeft: 0,
    },
    '& .MuiFormHelperText-root': {
      display: 'none',
    },
  },
}));

const DatePicker = ({
  onChange,
  variant = 'outlined',
  minDate,
  value,
  label,
  className,
  edge = false,
  ...rest
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const classes = useStyles();

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        margin="normal"
        open={isOpen}
        label={label || 'MM/DD/YYYY'}
        format="MM/dd/yyyy"
        value={value}
        fullWidth
        minDate={minDate}
        onClose={() => setIsOpen(!isOpen)}
        InputAdornmentProps={{
          position: 'end',
          component: (props) => (
            <InputAdornment position="end" {...props}>
              {Boolean(value) && (
                <IconButton onClick={() => onChange(null)} edge={edge}>
                  <CloseIcon />
                </IconButton>
              )}
              <IconButton onClick={() => setIsOpen(!isOpen)} edge={edge}>
                <CalendarIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        onChange={(date) => onChange(date)}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
        TextFieldComponent={(props) => (
          <TextField
            inputProps={{
              readOnly: true,
            }}
            {...props}
            variant={variant}
          />
        )}
        className={className || classes.datePicker}
        {...rest}
      />
    </MuiPickersUtilsProvider>
  );
};

export default DatePicker;
