export default function gestationPostpartumFilter(
  gestationPeriod,
  weeksGestation,
  weeksPostpartum
) {
  switch (gestationPeriod) {
    case '1t':
      if (
        weeksGestation !== null &&
        weeksGestation >= 0 &&
        weeksGestation < 9
      ) {
        return true;
      }
      break;
    case '2t':
      if (
        weeksGestation !== null &&
        weeksGestation >= 9 &&
        weeksGestation < 18
      ) {
        return true;
      }
      break;
    case '3t':
      if (
        weeksGestation !== null &&
        weeksGestation >= 18 &&
        weeksGestation < 28
      ) {
        return true;
      }
      break;
    case 'labor':
      if (
        weeksGestation !== null &&
        weeksGestation >= 28 &&
        weeksGestation < 38
      ) {
        return true;
      }
      break;
    case 'afterbirth':
      if (
        weeksGestation !== null &&
        weeksGestation >= 38 &&
        weeksGestation <= 42
      ) {
        return true;
      }
      break;
    case '1m':
      if (
        weeksPostpartum !== null &&
        weeksPostpartum >= 0 &&
        weeksPostpartum < 5
      ) {
        return true;
      }
      break;
    case '3m':
      if (
        weeksPostpartum !== null &&
        weeksPostpartum >= 5 &&
        weeksPostpartum < 14
      ) {
        return true;
      }
      break;
    case '6m':
      if (
        weeksPostpartum !== null &&
        weeksPostpartum >= 14 &&
        weeksPostpartum < 27
      ) {
        return true;
      }
      break;
    case '9m':
      if (
        weeksPostpartum !== null &&
        weeksPostpartum >= 27 &&
        weeksPostpartum < 40
      ) {
        return true;
      }
      break;
    case '1y':
      if (
        weeksPostpartum !== null &&
        weeksPostpartum >= 40 &&
        weeksPostpartum <= 52
      ) {
        return true;
      }
      break;
    default:
      return false;
  }
  return false;
}
