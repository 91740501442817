import React, { useState, useRef, useEffect, useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { formatPhoneNumber } from 'react-phone-number-input';
import DeleteConfirmModal from '../../common/DeleteConfirmModal/index.js';
import moment from 'moment';
import isPastDate from 'src/utils/isPastDate.js';
import {
  Avatar,
  Box,
  Card,
  InputAdornment,
  Link,
  SvgIcon,
  TextField,
  colors,
  makeStyles,
  Chip,
  Grid,
  Button,
  Tabs,
  Tab,
  CircularProgress,
  MenuItem,
  InputLabel,
  Select,
  FormControl,
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import getInitials from 'src/utils/getInitials';
import { DataGrid } from '@mui/x-data-grid';
import { CSVLink } from 'react-csv';
import axios from 'axios';
import DatePicker from 'src/components/common/DatePicker/index.js';
import gestationPostpartumFilter from 'src/utils/gestationPostpartumFilter.js';
import { UserContext } from 'src/context/UserContext.js';

const useStyles = makeStyles((theme) => ({
  csvButton: {
    height: '100%',
  },
  queryField: {
    width: '100%',
    '& input': {
      fontSize: 16,
      padding: 10,
      height: 40,
    },
  },
  statusField: {
    flexBasis: 200,
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    color: colors.common.white,
  },
  button: {
    backgroundColor: theme.palette.callToAction.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.callToAction.hover,
    },
  },
  inactiveChip: {
    backgroundColor: theme.palette.error.main,
    color: 'white',
  },
  activeChip: {
    backgroundColor: theme.palette.success.main,
    color: 'white',
  },
  pastDueClass: {
    color: theme.palette.error.main,
    background: theme.palette.error.light,
  },
  activeButton: {
    borderBottom: `2px solid ${theme.palette.primary.main}`,
    borderRadius: 0,
  },
  gestationPostpartumFilterClass: {
    '& .MuiSelect-select': {
      height: theme.spacing(3),
    },

    '& .MuiInputLabel-outlined': {
      transform: 'translate(14px, 24px) scale(1)',
      '&.MuiInputLabel-shrink': {
        transform: 'translate(14px, -6px) scale(.75)',
      },
    },
  },
}));

const PatientTable = ({ patients, setPatients, title }) => {
  const { isUserAdmin } = useContext(UserContext);
  const classes = useStyles();
  // const { isMobile } = useDevice();
  const [query, setQuery] = useState('');
  const [page, setPage] = useState(100);
  const [fromDate, setFromDate] = useState(null);
  const [gestationPeriod, setGestationPeriod] = useState('');
  const [toDate, setToDate] = useState(null);
  const [locale, setLocale] = useState('all');

  const [filteredPatients, setFilteredPatients] = useState(patients);

  const [isDeleteConfirmModalOpen, setIsDeleteConfirmModalOpen] =
    useState(false);
  const [patientToDelete, setPatientToDelete] = useState(null);
  const [exportData, setExportData] = useState([]);
  const [isExportLoading, setIsExportLoading] = useState(false);

  const csvLink = useRef();

  const handleCsvExport = async () => {
    try {
      setIsExportLoading(true);
      const { data: exportData } = await axios.get('/api/patients/export');
      setExportData(exportData);
      csvLink.current.link.click();
    } catch (error) {
      console.log(error.message);
    } finally {
      setIsExportLoading(false);
    }
  };

  const handleQueryChange = (event) => {
    event.persist();
    const query = event.target.value;
    setQuery(query);
  };

  const applyFilters = ({
    patients,
    query,
    toDate,
    fromDate,
    locale,
    gestationPeriod,
  }) => {
    const searchStrings = query
      .toLowerCase()
      .replace(/\s+/g, ' ')
      .trim()
      .split(' ');
    return patients?.filter((patient) => {
      const { weeksGestation, weeksPostpartum } = patient;
      let isWithinGestationPeriod = true;
      if (gestationPeriod !== '') {
        isWithinGestationPeriod = gestationPostpartumFilter(
          gestationPeriod,
          weeksGestation,
          weeksPostpartum
        );
      }
      const patientInfo = `${patient?.displayName?.trim()}`.toLowerCase();
      const patientDate = new Date(patient?.followUpDate);

      const hasKeyWord = searchStrings.every((el) =>
        patientInfo.includes(el.toLowerCase())
      );
      const isAfterFromDate = !fromDate || patientDate >= fromDate;
      const isBeforeToDate = !toDate || patientDate <= toDate;

      const isLocale = locale === 'all' || patient.locale === locale;

      return (
        hasKeyWord &&
        isAfterFromDate &&
        isBeforeToDate &&
        isLocale &&
        isWithinGestationPeriod
      );
    });
  };

  const columns = [
    {
      field: 'displayName',
      headerName: 'Name',
      sortable: false,
      flex: 2,
      renderCell: ({ row: patient }) => {
        return (
          <Box display="flex" alignItems="center">
            <Avatar className={classes.avatar} variant="rounded">
              {patient.displayName?.[0] || ''}
            </Avatar>
            <Box ml={2}>
              <Link
                variant="subtitle2"
                color="textPrimary"
                component={RouterLink}
                underline="none"
                to={`/patients/${patient.id}`}
                state={{
                  ...patient,
                }}
              >
                {patient.displayName}
              </Link>
            </Box>
          </Box>
        );
      },
    },
    {
      field: 'provider',
      headerName: 'Provider',
      sortable: false,
      flex: 2,
      renderCell: ({ row: patient }) => {
        return (
          <Box display="flex" alignItems="center">
            {patient.provider.firstName ? (
              <>
                <Avatar
                  className={classes.avatar}
                  src={`${patient.provider.avatarLink}`}
                >
                  {getInitials(
                    `${patient.provider.firstName} ${patient.provider.lastName}`
                  )}
                </Avatar>
                <Box ml={2}>
                  {patient.provider.firstName} {patient.provider.lastName}
                </Box>
              </>
            ) : (
              '-'
            )}
          </Box>
        );
      },
    },
    {
      field: 'followUpDate',
      headerName: 'Follow Up Date',
      flex: 1,
      // sortable: true,
      type: 'date',
      renderCell: ({ row: patient }) => {
        const isPastDue = isPastDate(patient.followUpDate2);
        return (
          <Chip
            label={
              patient.followUpDate
                ? moment.utc(patient.followUpDate).format('L')
                : '-'
            }
            className={clsx(isPastDue && classes.pastDueClass)}
          />
        );
      },
      sortComparator: (date1, date2) => {
        let dateA = Date.parse(date1) || 0;
        let dateB = Date.parse(date2) || 0;

        if (!dateA) {
          return 1;
        }

        if (!dateB) {
          return -1;
        }

        if (dateA === dateB) {
          return 0;
        }

        return dateA < dateB ? -1 : 1;
      },
    },
    {
      field: 'phoneNumber',
      headerName: 'Phone',
      sortable: false,
      flex: 1,
      valueFormatter: ({ row: patient }) =>
        formatPhoneNumber(patient.phoneNumber),
    },
    {
      field: 'email',
      headerName: 'Email',
      sortable: false,
      flex: 3,
      // valueFormatter: ({ row: patient }) => patient.email,
    },
    {
      field: 'weeksGestation',
      headerName: 'Weeks Gestation',
      sortable: true,
      flex: 1,
    },
    {
      field: 'weeksPostpartum',
      headerName: 'Weeks Postpartum',
      sortable: true,
      flex: 1,
    },
  ];

  const tabs = [
    {
      value: 'all',
      label: 'All',
    },
    {
      value: 'es',
      label: 'Spanish',
    },
  ];

  useEffect(() => {
    setFilteredPatients(
      applyFilters({
        patients,
        query,
        toDate,
        fromDate,
        locale,
        gestationPeriod,
      })
    );
  }, [patients, query, toDate, fromDate, locale, gestationPeriod]);

  return (
    <>
      {isUserAdmin && (
        <Box display="flex" marginBottom={2} height="40px">
          {/* Download CSV Button */}
          <Button
            onClick={handleCsvExport}
            color="primary"
            variant="contained"
            disabled={isExportLoading}
          >
            Download CSV
          </Button>
          {isExportLoading && (
            <Box
              display="flex"
              padding={2}
              justifyContent="center"
              alignItems="center"
            >
              <CircularProgress size={20} />
            </Box>
          )}

          <CSVLink
            data={exportData}
            filename="patients.csv"
            className="hidden"
            ref={csvLink}
            target="_blank"
          />
        </Box>
      )}

      <Card>
        <Box p={2}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={4}>
              <Tabs
                value={locale}
                indicatorColor="primary"
                textColor="primary"
                onChange={(e, newTabIndex) => setLocale(newTabIndex)}
                aria-label="filter group"
              >
                {tabs.map((tab) => (
                  <Tab key={tab.value} value={tab.value} label={tab.label} />
                ))}
              </Tabs>
            </Grid>

            <Grid item xs={12} md={2}>
              <DatePicker
                onChange={(newValue) => setFromDate(newValue)}
                label="FROM"
                value={fromDate}
              />
            </Grid>

            <Grid xs={12} md={2} item>
              <DatePicker
                onChange={(newValue) => setToDate(newValue)}
                label="TO"
                value={toDate}
              />
            </Grid>

            <Grid item xs={12} md={2}>
              <FormControl
                fullWidth
                variant="outlined"
                className={classes.gestationPostpartumFilterClass}
              >
                <InputLabel id="select-gestation-label">WG/PP</InputLabel>
                <Select
                  id="gestational-select"
                  labelId="select-gestation-label"
                  onChange={(newValue) => {
                    setGestationPeriod(newValue.target.value);
                  }}
                  value={gestationPeriod}
                  label="WG/PP"
                  aria-label="WG/PP"
                >
                  <MenuItem value="">None</MenuItem>
                  <MenuItem value="1t">1st Trimester</MenuItem>
                  <MenuItem value="2t">2nd Trimester</MenuItem>
                  <MenuItem value="3t">3rd Trimester</MenuItem>
                  <MenuItem value="labor">Labor & PP Prep</MenuItem>
                  <MenuItem value="afterbirth">Afterward Birth</MenuItem>
                  <MenuItem value="1m">1 Month</MenuItem>
                  <MenuItem value="3m">3 Months</MenuItem>
                  <MenuItem value="6m">6 Months</MenuItem>
                  <MenuItem value="9m">9 Months</MenuItem>
                  <MenuItem value="1y">1 Year</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={2}>
              <TextField
                className={classes.queryField}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon fontSize="small">
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  ),
                }}
                onChange={handleQueryChange}
                placeholder={`Search ${title}`}
                value={query}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </Box>

        <Box>
          <DataGrid
            rows={filteredPatients}
            columns={columns}
            sortingOrder={['desc', 'asc']}
            disableColumnMenu
            autoHeight
            pageSize={page}
            onPageSizeChange={(newPageSize) => setPage(newPageSize)}
            rowsPerPageOptions={[5, 10, 25]}
            pagination
            rowHeight={80}
          />
        </Box>
      </Card>

      <DeleteConfirmModal
        users={patients}
        isOpen={isDeleteConfirmModalOpen}
        setIsOpen={setIsDeleteConfirmModalOpen}
        user={patientToDelete}
        setUser={setPatientToDelete}
        setUsers={setPatients}
        type="patients"
      />
    </>
  );
};

PatientTable.propTypes = {
  className: PropTypes.string,
  patients: PropTypes.array.isRequired,
};

PatientTable.defaultProps = {
  patients: [],
};

export default PatientTable;
