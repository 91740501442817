import axios from 'axios';
import { toast } from 'react-toastify';

export const getPatientThreads = async (page) => {
  try {
    const { data: threadData } = await axios.get('/api/patients/threads', {
      params: { page },
    });
    return threadData;
  } catch (error) {
    toast.error(error.message);
    throw error;
  }
};
