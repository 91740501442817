import { createContext, useReducer } from 'react';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { useHistory } from 'react-router-dom';

export const UserContext = createContext();

const userReducer = (state, action) => {
  switch (action.type) {
    case 'SET_USER':
      return {
        ...state,
        user: action.payload,
        isUserAdmin: action?.payload?.role === 'Admin',
      };

    case 'SET_USER_TAGS':
      return {
        ...state,
        user: {
          ...state.user,
          tags: action.payload,
        },
      };

    case 'SET_AUTH_TOKEN':
      return {
        ...state,
        authToken: action.payload,
      };

    default:
      return state;
  }
};

const UserContextProvider = (props) => {
  const { getAccessTokenSilently, getIdTokenClaims, user } = useAuth0();
  const history = useHistory();
  const initialState = {
    user: null,
  };
  const [userState, dispatch] = useReducer(userReducer, initialState);

  const setUserTags = (updatedTags) => {
    dispatch({ type: 'SET_USER_TAGS', payload: updatedTags });
  };

  const setUser = async (authToken) => {
    try {
      const idTokenClaims = await getIdTokenClaims();
      if (!idTokenClaims.role) {
        throw new Error('no role assigned');
      }
      const { data } = await axios.get('/api/users/provider', {
        params: {
          auth0Id: user?.sub,
        },
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      if (!data) {
        throw new Error('Cannot set user');
      }

      dispatch({
        type: 'SET_USER',
        payload: {
          auth0Role: idTokenClaims.role,
          ...data,
        },
      });
    } catch (error) {
      history.push(
        `/?error=${encodeURIComponent(
          'User not setup correctly'
        )}&error_description=${encodeURIComponent(
          'User is not setup in the database or does not have role assigned.'
        )}`
      );
    }
  };

  const setAuthToken = async () => {
    const authToken = await getAccessTokenSilently();
    // Set Bearer token globally across all axios request
    axios.defaults.headers['Authorization'] = `Bearer ${authToken}`;
    dispatch({ type: 'SET_AUTH_TOKEN', payload: authToken });
    return authToken;
  };

  const value = {
    ...userState,
    setUserTags,
    setUser,
    setAuthToken,
  };

  return (
    <UserContext.Provider value={value}>{props.children}</UserContext.Provider>
  );
};

const UserContextConsumer = UserContext.Consumer;

export { UserContextProvider, UserContextConsumer };
