import { useState } from 'react';
import {
  TextField,
  InputAdornment,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  makeStyles,
  Typography,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
} from '@material-ui/core';
import {
  KeyboardArrowDown as DropDownIcon,
  Search as SearchIcon,
} from '@material-ui/icons';
import DatePicker from 'src/components/common/DatePicker';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '0 !important',
    '& .MuiAccordionSummary-root': {
      minHeight: '3rem',
    },
    '& .MuiAccordionSummary-content': {
      margin: 0,
    },
  },
}));

const Filter = ({
  handleSetQuery,
  query,
  handleSearchMessages,
  handleSetIsSearchOpen,
  handleClearSearch,
}) => {
  const classes = useStyles();
  const { root, actionButton } = classes;
  const [isExpanded, setIsExpanded] = useState(false);

  const handleExpandAccordion = (event, isExpanded) => {
    setIsExpanded(isExpanded);
    handleSetIsSearchOpen(isExpanded);
  };

  const handleSearch = () => {
    // Initial a new search from page 1
    handleSearchMessages(1);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  return (
    <Accordion
      expanded={isExpanded}
      onChange={handleExpandAccordion}
      className={root}
      id="search-accordion"
    >
      <AccordionSummary expandIcon={<DropDownIcon />}>
        <Typography>Search Messages</Typography>
      </AccordionSummary>
      <AccordionDetails id="search-accordion-details">
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              placeholder="Enter Keyword"
              fullWidth
              value={query.keyword}
              onKeyDown={handleKeyDown}
              onChange={(e) => handleSetQuery({ keyword: e.target.value })}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon color="primary" />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth variant="outlined">
              <InputLabel htmlFor="message-filter">Filter By</InputLabel>
              <Select
                id="message-filter"
                label="Filter By"
                value={query.filterByUserType}
                onChange={(event) => {
                  handleSetQuery({ filterByUserType: event.target.value });
                }}
              >
                <MenuItem value={''}>-</MenuItem>
                <MenuItem value="patient">Patient</MenuItem>
                <MenuItem value="provider">Provider</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <DatePicker
              onChange={(newValue) => {
                handleSetQuery({
                  fromDate: newValue
                    ? moment(newValue).format('YYYY/MM/DD')
                    : null,
                });
              }}
              label="FROM"
              value={query.fromDate}
            />
          </Grid>
          <Grid xs={12} item>
            <DatePicker
              onChange={(newValue) => {
                handleSetQuery({
                  toDate: newValue
                    ? moment(newValue).format('YYYY/MM/DD')
                    : null,
                });
              }}
              label="TO"
              value={query.toDate}
            />
          </Grid>
          <Grid item xs={6}>
            <Button
              className={actionButton}
              variant="contained"
              color="secondary"
              fullWidth
              onClick={handleClearSearch}
            >
              Clear
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleSearch}
            >
              Search
            </Button>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default Filter;
