import React, { useEffect, useState } from 'react';
import axios from 'axios';
import DoulaSearch from './DoulaSearch';
import DoulaItem from './DoulaItem';
import { Box, Typography, makeStyles } from '@material-ui/core';
import LoadingContent from '../common/LoadingContent';
import { Pagination } from '@material-ui/lab';
import asyncDebounce from 'src/utils/asyncDebounce';

const useStyles = makeStyles((theme) => ({
  pagination: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  results: {
    fontSize: '0.8rem',
  },
}));

const handleDoulaSearch = async (query) => {
  try {
    const { data: doulasData } = await axios.get(`/api/doulas`, {
      params: query,
    });
    return doulasData;
  } catch (error) {
    throw error;
  }
};

const debouncedSearch = asyncDebounce(handleDoulaSearch, 500, {
  leading: true,
});

const Doulas = ({ handleScrollToTop }) => {
  const [doulasData, setDoulasData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [query, setQuery] = useState({
    page: 1,
    keyword: '',
  });
  const classes = useStyles();

  const handlePageChange = async (event, value) => {
    handleScrollToTop();
    setQuery({ ...query, page: value });
  };

  const handleSetKeyword = (keyword) => {
    setQuery({ page: 1, keyword });
  };

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const doulasData = await debouncedSearch(query);
        setDoulasData(doulasData);
      } catch (error) {
        setHasError(true);
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [query]);

  const pageSize = doulasData?.pageSize;
  const doulas = doulasData?.doulas;
  const totalResults = doulasData?.pagination?.total;
  const currentPage = doulasData?.pagination?.page;

  const startIndex = currentPage * pageSize - pageSize;
  const endIndex = currentPage * pageSize > totalResults ? totalResults : currentPage * pageSize;
  const showning = Boolean(doulas?.length) ? `${startIndex + 1}-${endIndex}` : 0;

  return (
    <>
      <DoulaSearch keyword={query.keyword} handleSetKeyword={handleSetKeyword} />

      <Typography variant="h5" color="textPrimary">
        <strong>Results </strong>
        <span className={classes.results}>
          {showning} out of {totalResults}
        </span>
      </Typography>

      {isLoading && <LoadingContent />}
      {hasError && (
        <Typography variant="h6" color="error" align="center">
          Error loading doulas
        </Typography>
      )}
      {!isLoading && !hasError && !Boolean(doulas?.length) && (
        <Typography variant="h6" color="textPrimary" align="center">
          No doulas found
        </Typography>
      )}
      {!isLoading && !hasError && Boolean(doulas?.length) && (
        <>
          <Box>
            {doulas.map((doula) => (
              <DoulaItem key={doula.id} doula={doula} />
            ))}
          </Box>
          <Box display="flex" justifyContent="center">
            <Pagination
              count={Math.ceil(totalResults / pageSize)}
              page={currentPage}
              onChange={handlePageChange}
              color="primary"
              variant="outlined"
              shape="round"
              siblingCount={1}
              boundaryCount={1}
              classes={{ ul: classes.pagination }}
            />
          </Box>
        </>
      )}
    </>
  );
};

export default Doulas;
