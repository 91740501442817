import { useState, useEffect } from 'react';
import { Card, Box, makeStyles, Grid } from '@material-ui/core';
import DatePicker from 'src/components/common/DatePicker';
import axios from 'axios';
import moment from 'moment';
import { toast } from 'react-toastify';
import ProviderSelect from 'src/components/common/ProviderSelect';

const useStyles = makeStyles((theme) => ({
  datePickerClass: {
    '& .MuiFormHelperText-root': {
      display: 'none',
    },
  },
}));

const Provider = ({
  provider: initialProvider,
  followUpDate: initialFollowUpDate,
  patientId,
}) => {
  const [followUpDate, setFollowUpDate] = useState(null);
  const { datePickerClass } = useStyles();

  const handleSaveNewProvider = async (newOption) => {
    try {
      await axios.put(`/api/patients/${patientId}`, {
        providerId: newOption.id,
      });
    } catch (error) {
      toast.error(`Error updating provider`);

      throw error;
    }
  };

  const handleSubmitFollowUpDate = async (value) => {
    try {
      let date = null;
      if (value) {
        date = moment(value).utc().startOf('day').format();
      }

      await axios.put(`/api/patients/${patientId}`, {
        followUpDate: date,
      });

      setFollowUpDate(value);
    } catch (error) {
      toast.error(`Error updating Follow up Date`);
      throw error;
    }
  };

  const handleChange = (newValue) => {
    handleSaveNewProvider(newValue);
  };

  useEffect(() => {
    if (initialFollowUpDate) {
      // Set intial date to
      const adjustedDate =
        moment.parseZone(initialFollowUpDate).format('MM/DD/YYYY') || null;

      setFollowUpDate(adjustedDate);
    }
  }, [initialFollowUpDate]);

  return (
    <Card>
      <Box p={2}>
        <Grid container alignItems="center" spacing={1}>
          <Grid item xs={12}>
            <Box mt={1}>
              <ProviderSelect
                label="Provider"
                provider={initialProvider}
                handleChange={(newValue) => {
                  handleChange(newValue);
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <DatePicker
              className={datePickerClass}
              disableToolbar
              variant="outlined"
              format="MM/dd/yyyy"
              margin="normal"
              inputVariant="outlined"
              id="format-date"
              label="Date"
              value={followUpDate}
              minDate={new Date()}
              fullWidth
              edge="end"
              InputProps={{ shrink: 'true' }}
              onChange={(followUpDate) => {
                handleSubmitFollowUpDate(followUpDate);
              }}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
};

export default Provider;
