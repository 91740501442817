// Import dependencies
import React from 'react';
import queryString from 'query-string';
import { Box, Typography } from '@material-ui/core';
import SearchItem from './SearchItem';
import InfiniteScroll from 'react-infinite-scroll-component';

const SearchList = ({ handleSearchMessages, state }) => {
  const {
    messageSearchCurrentPage,
    messageSearchResults,
    messageSearchTotalPages,
    query,
  } = state;
  const { messageId } = queryString.parse(window.location.search);

  const handleGetMoreSearchResults = async () => {
    const newPageCurrentPage = messageSearchCurrentPage + 1;
    handleSearchMessages(newPageCurrentPage);
  };

  if (messageSearchTotalPages === 0) {
    return (
      <Box py={2}>
        <Typography variant="subtitle2" color="textSecondary" align="center">
          No results found
        </Typography>
      </Box>
    );
  }

  return (
    <Box height="100%" overflow="auto" id="search-list">
      <InfiniteScroll
        totalPages={messageSearchTotalPages}
        dataLength={messageSearchResults.length}
        // Need to assume true if null to prevent Endmessage from showing
        hasMore={
          messageSearchTotalPages === null
            ? true
            : messageSearchCurrentPage < messageSearchTotalPages
        }
        next={handleGetMoreSearchResults}
        scrollableTarget="search-list"
        endMessage={
          <Box py={2}>
            <Typography
              variant="subtitle2"
              color="textSecondary"
              align="center"
            >
              No more results
            </Typography>
          </Box>
        }
      >
        {messageSearchResults?.map((message) => (
          <SearchItem
            key={message.id}
            message={message}
            active={message.id === parseInt(messageId)}
            keyword={query.keyword}
          />
        ))}
      </InfiniteScroll>
    </Box>
  );
};
export default SearchList;
